<template>
  <div class="MolecularGeneration">
    <h1 class="title">Molecular Generation</h1>
    <div class="Input">
      <div class="Sub-Input">
        <section>
          <div>
            <h2>Select a Target</h2>
            <el-select class="target-input" size="large" placeholder="Please Select Model" v-model="selectedTarget"
              filterable>
              <el-option v-for="item in modelList" :key="item.name" :label="item.label" :value="item.name_use_on_web">
              </el-option>
            </el-select>
            <div class="select_data">
              <div class="mode" v-for="(models, mode, idx) in model" :key="mode">
                <div class="mode-bar">
                  <div class="mode-name">{{ mode }}</div>
                  <div class="show-all-btn" @click="onClickShowAll(idx)">
                    <p v-if="!isShowAll[idx]">view all</p>
                    <p v-else>Fold</p>
                  </div>
                </div>
                <div :class="['models', isShowAll[idx] ? 'show-all' : '']">
                  <div class="model-item" v-for="model in models" :key="model.name"
                    @click="onSelectModel(model.category, model.name)">
                    <img class="model-icon" src="../assets/model_icon.svg" alt="" />
                    <a :title="model.name_use_on_web">
                      {{ model.name_use_on_web }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="drawboard">
            <h2>Submit with an advantag fragment</h2>
            <el-input placeholder="Please enter the formula" class="smiles-input" size="large"
              v-model="molecularFormula"></el-input>
            <iframe src="./static/jsme.html" frameborder="0" class="draw-molecular" height="340px"
              width="450px"></iframe>
          </div>
        </section>
      </div>
      <div class="submit">
        <img src="../assets/singleprediction/photo1.png" alt="" />
        <el-button id="submit-btn" @click="onSubmit">Submit</el-button>
      </div>
    </div>

    <moleculargeneration-popper v-show="showPopper" :infos="popperInfo" @onSubmit="onSubmitPopper"
      @onCancel="onCancelPopper"></moleculargeneration-popper>
  </div>
</template>

<script>
import modelData from "../api/data/gen_mol_models.json";
import { molecularGenerationApi } from "../api/index";
import popper from "../components/popper/popper.vue";

export default {
  name: "contact",
  data() {
    return {
      selectedTarget: "",
      molecularFormula: "",
      value: "",
      modelList: [],
      showPopper: false,
      isShowAll: [0, 0, 0, 0, 0, 0],
      popperInfo: {},
      taskName: "",
      model: {
        "Anti-cancer Targets": [],
        Kinase: [],
        "Virus Targets": [],
      },
      searchValue: null,
    };
  },
  created() {
    window.getFromIframe = this.getFromIframe;
  },
  mounted() {
    for (let i = 0; i < modelData.length; i++) {
      this.modelList.push(modelData[i]);
      this.model[modelData[i].category_use_on_web].push(modelData[i]);
    }
    console.log(this.model);
  },
  methods: {
    onClickShowAll(idx) {
      this.isShowAll[idx] = !this.isShowAll[idx];
    },
    onSubmitPopper(taskName) {
      this.taskName = taskName;
      this.SubmitGeneration();
      this.showPopper = false;
    },
    onCancelPopper() {
      this.showPopper = false;
      console.log("oncancel");
    },
    checkForm() {
      //检查登陆
      if (!this.$store.getters.getToken) {
        this.$store.commit("alertMsg", {
          msg: "Please login first!",
          type: "error",
        });
        this.$router.push({
          path: "/account/sign-in",
          query: { redirect: this.$route.path },
        });
        return false;
      }
      if (this.selectedTarget == "") {
        this.$store.commit("alertMsg", {
          msg: "No Target is selected!",
          type: "error",
        });
        return false;
      }
      return true;
    },
    onSubmit() {
      if (this.checkForm()) {
        this.popperInfo = {
          target: this.selectedTarget,
          smiles: this.molecularFormula,
        };
        this.showPopper = true;
      }
    },
    SubmitGeneration() {
      molecularGenerationApi
        .sumbitGeneration({
          target: this.selectedTarget,
          smiles: this.molecularFormula,
          title: this.taskName,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$store.commit("alertMsg", {
              msg: "Submit sucessfully",
              type: "ok",
            });
            setTimeout(() => {
              this.$router.push({
                path: "/user/molecular_history",
              });
            }, 1200);
          } else if (res.code == 401) {
            this.$store.commit("alertMsg", {
              msg: "Please login first!",
              type: "error",
            });
            this.$router.push({
              path: "/account/sign-in",
              query: { redirect: this.$route.path },
            });
          } else {
            this.$store.commit("alertMsg", {
              msg: res.msg,
              type: "error",
            });
          }
        });
    },
    getFromIframe(value) {
      console.log(value);
      this.molecularFormula = value;
    },
    onSelectModel(catagory, name) {
      this.selectedTarget = name;
    },
  },

  components: {
    "moleculargeneration-popper": popper,
  },
};
</script>

<style scoped>
h2 {
  font-size: 20pt
}

.draw-molecular {
  margin-top: 10px;
}

.title {
  font-size: 30pt;
  margin: 20px 0;
  padding: 0 10px;
  text-align: center;
}

.Input {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.drawboard {
  display: flex;
  flex-direction: column;
}

.Sub-Input {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.Sub-Input section {
  /* flex: 1; */
  display: flex;
}

.target-input {
  margin-top: 10px;
  width: 50%;
}

.smiles-input {
  margin-top: 10px;
  width: 50%;
}

.title-font {
  font-size: 28pt;
  font-weight: 700;
}

.text-font {
  font-size: 14pt;
  font-weight: 500;
}

.MolecularGeneration {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  padding-top: 100px;
  color: white;
}

.column-flex {
  display: flex;
  flex-direction: column;
}

.row-flex {
  display: flex;
  flex-direction: row;
}

.introduction {
  width: 50%;
}

.introduction p {
  font-size: 14pt;
  word-break: break-all;
}

.example {
  width: 100%;
  height: 500px;
  display: flex;
  background: rgb(151, 248, 255, 0.89);
  flex-direction: column;
}

.submit {
  width: 100%;
  display: flex;
  margin-top: 50px;
  flex-direction: column;
  align-items: center;
}

.submit img {
  width: 90%;
}

.option-list {
  border-bottom: 1px solid #000;
}

.option-list .el-row {
  height: 30px;
  line-height: 30px;
}

.option-list .el-row .el-col {
  font-size: 14pt;
}

.mode {
  width: fit-content;
  /* background-color: var(--color2-dark);  */
  box-sizing: border-box;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.mode-bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* background: red; */
}

.mode-name {
  font-size: 16pt;
  font-weight: bolder;
  margin: 5px 0;
}

.show-all-btn {
  width: fit-content;
  align-self: end;
  padding: 0 10px;
  font-size: 16px;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  align-self: flex-end;
}

.show-all-btn:hover {
  color: var(--color2-dark);
}

.model-select {
  margin: 0 20px;
}

.models {
  width: fit-content;
  height: 40px;
  display: grid;
  overflow-y: hidden;
  overflow-x: hidden;
  margin: 10px;
  grid-template-columns: repeat(4, 150px);
  gap: 10px;
}

.models.show-all {
  /* overflow-y: scroll; */
  height: fit-content;
}

.model-item {
  box-sizing: border-box;
  height: 30px;
  background-color: var(--color2-dark);
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px;
  text-align: center;
  color: var(--color1-dark);
  display: flex;
}

.model-icon {
  /* width: 10px; */
  padding: 0 2px;
  height: 100%;
}

.model-item a {
  flex: 1;
  text-align: center;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}

.model-item:hover {
  color: white;
  /* white-space: nowrap;
  min-width: fit-content;
  overflow: visible;*/
}

#submit-btn {
  height: 40px;
  margin: 30px;
  border-radius: 15px;
  font-size: 20pt;
  padding: 6px 0;
  width: 350px;
  border: none;
  color: black;
  outline: none;
  cursor: pointer;
  background: linear-gradient(to right,
      var(--color2-dark),
      var(--color2-light));
}
</style>
